import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import FlexContentHandler from 'components/shared/FlexContentHandler'

interface PageTemplateProps {
  data: {
    page: {
      path?: string
      title?: string
      seo: any
      flexContent: any
    }
  }
  location?: any
}

const PageTemplate: React.FC<PageTemplateProps> = ({
  data: {
    page: { flexContent, seo },
  },
  location = {},
}) => (
  <Layout>
    <SEO seo={seo} />

    <FlexContentHandler
      prefix="Vacature_Flexcontent"
      fields={flexContent}
      location={location}
    />
  </Layout>
)

export const pageQuery = graphql`
  query vacatureById($pageId: String!) {
    page: wpVacature(id: { eq: $pageId }) {
      ...generalVacatureFragment
    }
  }
`

export default PageTemplate
